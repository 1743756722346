import { MainActions, WorkerActions } from "../action-types";
import { generateReducers } from "../../utils/generate-reducers";
import WebpackWorker from "worker-loader!*";
import { timestamper } from "../../utils/timestamper";
import { Line } from "../../utils/line.class";
import { charToInt } from "../utils";

const MAX_DURATION = 5e3;

export type FasterPhalangesState = {
  linesOfText: Line[];
  cursor: number;
  prevCharInt: number | null;
  prevTime: number | null;
  mean: number | null;
  worker: WebpackWorker | null;
};

export type FasterPhalangesReducer<T> = (
  state: FasterPhalangesState,
  payload: T
) => FasterPhalangesState;

const setWorker: FasterPhalangesReducer<WebpackWorker> = (state, worker) => ({
  ...state,
  worker,
});

const handleKeypress: FasterPhalangesReducer<string> = (state, char) => {
  const { linesOfText, cursor, prevTime, prevCharInt, worker, mean } = state;
  if (worker === null || linesOfText.length < 3) return state;
  const currentLineKeys = linesOfText[2].keys;
  const keypressTime = timestamper();
  const charInt = charToInt(char);
  const currentKey = currentLineKeys[cursor];
  if (charInt !== currentKey.charInt) {
    currentKey.mistakes = Math.min(currentKey.mistakes + 1, 5);
    return state;
  }
  if (prevTime !== null && prevCharInt !== null) {
    const duration =
      keypressTime - prevTime + currentKey.mistakes * (mean ?? 0);
    if (duration < MAX_DURATION) {
      worker.postMessage({
        type: WorkerActions.updateBigram,
        payload: { prevCharInt, charInt, duration },
      });
    }
  }
  const nextCursor = cursor + 1;
  if (nextCursor === currentLineKeys.length) {
    linesOfText.shift();
    if (linesOfText.length < 5) {
      worker.postMessage({
        type: WorkerActions.getNextLine,
      });
    }
    return {
      ...state,
      linesOfText,
      cursor: 0,
      prevCharInt: null,
      prevTime: null,
    };
  }
  return {
    ...state,
    linesOfText,
    cursor: nextCursor,
    prevCharInt: charInt,
    prevTime: keypressTime,
  };
};

const handleNextLine: FasterPhalangesReducer<{ line: Line; mean: number }> = (
  state,
  { line, mean }
) => {
  const { linesOfText, worker } = state;
  linesOfText.push(line);
  if (worker !== null && linesOfText.length < 5) {
    worker.postMessage({
      type: WorkerActions.getNextLine,
      payload: null,
    });
  }
  return {
    ...state,
    mean,
    linesOfText,
  };
};

const workerReady: FasterPhalangesReducer<void> = (state) => {
  const { worker, linesOfText } = state;
  if (worker !== null && linesOfText.length < 5) {
    worker.postMessage({
      type: WorkerActions.getNextLine,
      payload: null,
    });
  }
  return state;
};

export const stateReducers = generateReducers<
  MainActions,
  FasterPhalangesReducer<any>
>({
  [MainActions.handleKeypress]: handleKeypress,
  [MainActions.handleNextLine]: handleNextLine,
  [MainActions.workerReady]: workerReady,
  [MainActions.setWorker]: setWorker,
});
