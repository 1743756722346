import { useEffect, useReducer } from "react";
import FasterPhalangesWorker from "worker-loader!../faster-phalanges.worker";
import { MainActions } from "../action-types";
import { FasterPhalangesState, stateReducers } from "./state-reducers";

const initialState: FasterPhalangesState = {
  linesOfText: [],
  cursor: 0,
  prevCharInt: null,
  prevTime: null,
  mean: null,
  worker: null,
};

type HandleKeypress = (payload: string) => void;
type UseFasterPhalanges = () => Omit<
  FasterPhalangesState,
  "prevCharInt" | "prevTime" | "worker"
> & {
  handleKeypress: HandleKeypress;
};

export const useFasterPhalanges: UseFasterPhalanges = () => {
  const [state, dispatch] = useReducer(stateReducers, initialState);

  const { cursor, linesOfText, mean } = state;

  const handleKeypress: HandleKeypress = (payload) =>
    dispatch({ type: MainActions.handleKeypress, payload });

  useEffect(() => {
    const worker = new FasterPhalangesWorker();
    worker.onmessage = ({ data: action }) => dispatch(action);
    dispatch({
      type: MainActions.setWorker,
      payload: worker,
    });
    return () => {
      //@ts-ignore
      worker.terminate();
    };
  }, []);

  return { linesOfText, handleKeypress, mean, cursor };
};
