import React, { FunctionComponent, useEffect, useRef } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

// @ts-ignore
import Layout from "../../components/layout";
import {
  Typography,
  CircularProgress,
  Link as MuiLink,
} from "@material-ui/core";
import { useFasterPhalanges } from "../../projects/faster-phalanges/use-faster-phalanges";
import { durationToWPM } from "../../projects/utils/duration-to-wpm";
import { intToChar } from "../../projects/faster-phalanges/utils";
import { transparentize } from "polished";
import { graphql, Link as GatsbyLink, PageProps } from "gatsby";
import { Helmet } from "react-helmet";

export const query = graphql`
  query {
    file(name: { eq: "project-faster-phalanges" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`;

const Letters = styled.h1<{ index: number }>`
  font-family: monospace;
  white-space: pre;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  transition: height 0.3s ease, opacity 0.3s ease;
  height: ${(p) => (p.index === 0 || p.index === 4 ? 0 : "1.5em")};
  opacity: ${(p) => (p.index === 2 ? 1 : 0.1)};
`;

const Letter = styled.span<{
  prev: boolean;
  current: boolean;
  mistakesAdjustment: number;
}>`
  position: relative;
  color: rgba(
    255,
    ${(p) => p.mistakesAdjustment},
    ${(p) => p.mistakesAdjustment},
    ${(p) => (p.prev ? 0.3 : 1)}
  );
  transition: color 0.3s ease;
  ::after {
    opacity: ${(p) => (p.current ? 1 : 0)};
    position: absolute;
    content: "🔺";
    font-size: 0.4em;
    text-align: center;
    bottom: -1ch;
    left: -0.5ch;
    right: -0.5ch;
  }
`;

const Input = styled.input.attrs({
  value: "",
  placeholder: "CLICK TO ACTIVATE",
  autoCapitalize: "none",
  autoCorrect: "false",
})`
  resize: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: default;
  border: none;
  backdrop-filter: blur(0.5rem);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: 0;
  background-color: ${(p) => transparentize(0.5, p.theme.palette.primary.main)};
  text-align: center;
  transition: box-shadow 0.1s ease, background-color 0.1s ease;
  ::placeholder {
    color: ${(p) => p.theme.palette.primary.contrastText};
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12);
    ${(p) => p.theme.typography.h5}
  }
  :focus {
    backdrop-filter: none;
    background-color: transparent;
    cursor: none;
    color: transparent;
    box-shadow: ${(p) => p.theme.shadows[8]};
    outline: none;
    ::placeholder {
      opacity: 0;
    }
  }
`;

const PageTitle = styled(Typography).attrs({ variant: "h1" })`
  text-align: center;
`;

const WpmNumber = styled(Typography).attrs({
  variant: "h1",
})`
  margin: 0;
  color: ${(p) => p.theme.palette.text.secondary};
`;

const WpmLabel = styled(Typography).attrs({
  variant: "h6",
})`
  margin: 0;
  color: ${(p) => p.theme.palette.text.hint};
`;

const WpmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  @media (max-width: 600px) {
    display: none;
  }
`;

const DisplayConainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 1rem 0;
  transform: translateZ(0);
  font-size: 100%;
  @media (max-width: 800px) {
    font-size: 2vw;
  }
`;

const LayoutWrapper: FunctionComponent<PageProps> = ({ children, data }) => (
  <Layout
    title="Faster Phalanges"
    keywords={["tensorflow", "typing", "practice", "keyboard", "training"]}
    description="Practice typing with an AI that helps you train"
  >
    <Helmet>
      <meta property="og:image" content={data.file.childImageSharp.fluid.src} />
    </Helmet>
    <PageTitle>Faster Phalanges</PageTitle>
    {children}
    Want a challenge? Practice using pseudo-words in{" "}
    <MuiLink component={GatsbyLink} to="/projects/rnn-typing-practice">
      RNN Typing Practice
    </MuiLink>
  </Layout>
);

const FasterPhalangesPage: FunctionComponent<PageProps> = (props) => {
  const keyboardLoggerRef = useRef<HTMLInputElement>(null);

  const { linesOfText, handleKeypress, cursor, mean } = useFasterPhalanges();

  useEffect(() => {
    keyboardLoggerRef.current?.focus();
  }, [keyboardLoggerRef]);

  return (
    <LayoutWrapper {...props}>
      <DisplayConainer>
        <WpmContainer>
          <WpmNumber>
            {mean !== null ? durationToWPM(mean).toFixed(2) : "0.0"}
          </WpmNumber>
          <WpmLabel>
            <abbr title="Words Per Minute">WPM</abbr>
          </WpmLabel>
        </WpmContainer>
      </DisplayConainer>
      {linesOfText.length >= 3 ? (
        <InputContainer>
          <Input
            ref={keyboardLoggerRef}
            onChange={(e) => handleKeypress(e.target.value)}
          />
          {linesOfText.map(({ time, keys }, lineIndex) => (
            <Letters key={time} index={lineIndex}>
              {keys.map(({ timeStamp, charInt, mistakes }, charIndex) => (
                <Letter
                  key={timeStamp}
                  mistakesAdjustment={255 / (mistakes + 1)}
                  current={lineIndex === 2 && cursor === charIndex}
                  prev={lineIndex === 2 && cursor > charIndex}
                >
                  {intToChar(charInt)}
                </Letter>
              ))}
            </Letters>
          ))}
        </InputContainer>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="1rem"
        >
          <CircularProgress size="3rem" />
        </Box>
      )}
    </LayoutWrapper>
  );
};

export default FasterPhalangesPage;
