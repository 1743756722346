export enum MainActions {
  handleKeypress,
  handleNextLine,
  workerReady,
  setWorker,
}

export enum WorkerActions {
  getNextLine,
  updateBigram,
}
