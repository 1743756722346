const spaceCharCode = " ".charCodeAt(0);
const tildeCharCode = "~".charCodeAt(0);

export const numCharacters = tildeCharCode - spaceCharCode;
export const bigramShape: [number] = [numCharacters ** 2];
export const spaceInt = 0;
export const charToInt = (char: string): number =>
  char.charCodeAt(0) - spaceCharCode;
export const intToChar = (int: number): string =>
  String.fromCharCode(int + spaceCharCode);
