export type Reducer<S, P> = (state: S, payload: P) => S;

const defaultReducer: Reducer<any, any> = (state) => state;

type MapTypeToReducer<T extends string | number, R> = Record<T, R>;

type GenerateReducers = <T extends string | number, R>(
  mapTypeToReducer: MapTypeToReducer<T, R>
) => (state: any, props: { type: T; payload: any }) => any;

export const generateReducers: GenerateReducers = (mapTypeToReducer) => (
  state,
  { type, payload }
) => {
  const { [type]: reducer = defaultReducer } = mapTypeToReducer;
  return reducer(state, payload);
};
